import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#111"
        d="M23.404 8.254A11.99 11.99 0 0 1 24 11.999C24 18.627 18.628 24 12 24c-.353 0-.701-.015-1.046-.044l5.394-9.131c.526-.81.832-1.776.832-2.815a5.154 5.154 0 0 0-1.617-3.755l7.84-.001ZM2.093 5.227l5.335 9.18a5.17 5.17 0 0 0 6.005 2.575l-3.986 6.745C4.047 22.557 0 17.752 0 12c0-2.512.772-4.845 2.093-6.772Zm9.916 3.027a3.757 3.757 0 0 1 3.282 5.588l-.032.046-.1.172a3.755 3.755 0 0 1-6.384-.139l-.014-.033-.07-.115a3.756 3.756 0 0 1 3.32-5.52h-.002ZM12 0a12.002 12.002 0 0 1 10.838 6.84H12.01a5.172 5.172 0 0 0-5.033 3.981L3.031 4.026A11.971 11.971 0 0 1 12 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
