import styled, { css } from 'styled-components'
import { Media } from '@raylink/common/theme'

export const PersonalWrap = styled.div`
  width: 100%;
`

export const ExplainItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 140px;
  padding: 140px 0;
  ${Media.ipad`
    gap: 120px;
    padding: 100px 0;
  `}
  ${Media.phone`
    gap: 0.8rem;
    padding: 0.8rem 0 0.64rem;
  `}
`

export const SchoolOpenActivity = css`
  /*** 返校季活动 */
  .second {
    position: relative;
    &::after {
      content: '限时7折起';
      position: absolute;
      top: -16px;
      right: -24px;
      color: #fff;
      font-size: 14px;
      background: linear-gradient(89.33deg, #ff9468 0.31%, #ff5e7d 77.62%);
      padding: 1px 6px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`
