import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import Bg from '../../../../../images/product/personal/bg.png'
import IpadBg from '../../../../../images/product/personal/ipadBg.png'
import MobileBg from '../../../../../images/product/personal/mobileBg.png'
import { Media } from '@raylink/common/theme'
import { SchoolOpenActivity } from '../../index.style'

export const PersonalBannerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 680px;
  min-width: 1200px;
  background: url(${Bg}) center center / auto 100% no-repeat;
  ${Media.desktopContact`
    background-size: 100% auto;
  `}
  ${Media.ipad`
    height: 640px;
    min-width: 944px;
    background: url(${IpadBg}) center center / auto 100% no-repeat;
  `}
  ${Media.phone`
    background: url(${MobileBg}) center center / auto 100% no-repeat;
    background-size: 100% 100%;
    height: 12.4rem;
    min-width: 100%;
  `}
`
export const PersonalBannerContent = styled(Warpper)`
  padding-top: 176px;
  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    padding: 0 30px;
    font-size: 18px;
    border-radius: 26px;
    margin-bottom: 17px;
    line-height: 1.5;
    color: #22494b;
    background: rgba(17, 200, 113, 0.1);
  }
  .title {
    width: 406px;
    color: #212121;
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    h1 {
      margin: 0;
      font-size: inherit;
    }
  }
  .p-btns {
    margin: 30px 0 38px;
    /*** 开学季活动 */
    ${SchoolOpenActivity}
  }
  ${Media.phone`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0.4rem 0;
    .tag {
      height: 0.6rem;
      padding: 0 0.45rem;
      font-size: 0.27rem;
      border-radius: 0.39rem;
      margin-bottom: 0.25rem;
    }
    .title {
      width: 100%;
      font-size: 0.72rem;
      line-height: 0.9rem;
      text-align: center;
      h1 {
        display: block;
      }
    }
    .p-btns {
      margin: 0.45rem 0 0.54rem;
    }
  `}
`

export const BtnListWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #878787;
  a {
    display: flex;
    align-items: center;
  }
  svg {
    &:hover {
      path {
        fill: #11c871;
      }
    }
  }
  ${Media.ipad`
    gap: 10px;
  `}
  ${Media.phone`
    gap: 0.16rem;
    font-size: 0.28rem;
    svg {
      width: 0.4rem;
      height: auto;
    }
  `}
`
