import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const SimpleWrap = styled.div`
  padding: 60px 0;
  background: radial-gradient(
    100% 711.11% at 100% 13.51%,
    #11c8b2 0%,
    #11c871 100%
  );
  ${Media.phone`
    padding: 1.2rem 0 0.9rem;
  `}
`

export const SimpleContent = styled(Warpper)`
  margin: auto;
  text-align: center;
  .white-logo {
    width: 190px;
  }
  .title {
    margin: 20px 0 24px;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
  }
  .simple-btns {
    justify-content: center;
    .first {
      background: #fff;
      color: #11c871;
      &:hover {
        box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.3);
      }
    }
    .second {
      color: #fff;
      border: 1px solid #fff;
      &:hover {
        color: #11c871;
        background: #fff;
      }
    }
    /** 开学季活动 */
    .second {
      color: #fff;
      border: 1px solid #fff;
      &:hover {
        color: #11c871;
        background: #fff;
      }
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: -16px;
        right: -24px;
        background: linear-gradient(
          95.23deg,
          #ffffff 0%,
          #ffffff 80.24%,
          #c8ffe5 100%
        );
        width: 76px;
        height: 22px;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &::after {
        content: '限时7折起';
        position: absolute;
        top: -16px;
        right: -24px;
        height: 22px;
        line-height: 22px;
        background: linear-gradient(90deg, #11c871 0%, #42afd7 100%);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 14px;
        padding: 1px 6px;
      }
    }
  }
  ${Media.phone`
    .white-logo {
      width: 3.42rem;
    }
    .title {
      margin: 0.48rem 0 0.92rem;
      font-size: 0.48rem;
    }
  `}
`
