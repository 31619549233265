import React, { useEffect, useState } from 'react'
import {
  ExplainWrap,
  ExplainWebWrap,
  Left,
  FeatListWrap,
  FeatItem,
  Right,
  ExplainPhoneWrap,
  FeatListPhoneWrap,
  FeatItemPhone,
} from './atom'

export function Explain({ data, isPlay, id }) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const interval = setInterval(() => {
      if (activeIndex === data.featList.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(pre => pre + 1)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [activeIndex, isPlay])

  return (
    <ExplainWrap id={id}>
      {/* web端 */}
      <ExplainWebWrap isFlip={!!data.isFlip}>
        <Left>
          <h2 className="title">{data.title}</h2>
          <FeatListWrap>
            {data.featList.map((item, index) => (
              <FeatItem
                key={item.name}
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              >
                <div className="feat-content">
                  <h3 className="f-title">{item.name}</h3>
                  {activeIndex === index && (
                    <div className="f-desc">{item.desc}</div>
                  )}
                </div>
              </FeatItem>
            ))}
          </FeatListWrap>

          {/* {data.jumpText && (
            <a href="/" className="jump">
              {data.jumpText}
            </a>
          )} */}
        </Left>
        <Right>
          <img src={data.featList[activeIndex]?.imgSrc} alt="" loading="lazy" />
        </Right>
      </ExplainWebWrap>

      {/* 移动端 */}
      <ExplainPhoneWrap>
        <h2 className="title">{data.title}</h2>
        <FeatListPhoneWrap>
          {data.featList.map((item, index) => (
            <FeatItemPhone key={item.name} isActive={activeIndex === index}>
              <FeatItem
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              >
                <div className="feat-content">
                  <div className="f-title">{item.name}</div>
                  {activeIndex === index && (
                    <div className="f-desc">{item.desc}</div>
                  )}
                </div>
              </FeatItem>
              {activeIndex === index && (
                <img
                  src={item.imgSrc}
                  alt=""
                  className="feat-img"
                  loading="lazy"
                />
              )}
            </FeatItemPhone>
          ))}
        </FeatListPhoneWrap>
        {/* {data.jumpText && (
          <a href="/" className="jump">
            {data.jumpText}
          </a>
        )} */}
      </ExplainPhoneWrap>
    </ExplainWrap>
  )
}
