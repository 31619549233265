import React, { useEffect, useState } from 'react'
import { Layout, SEO } from '@raylink/common/components'
import { PersonalBanner } from './component/personalBanner'
import { Explain } from './component/explain'
import { remoteExplainData, securityExplainData, WrapId } from './config'
import { PersonalWrap, ExplainItemsWrap } from './index.style'
import { isElementInViewport } from '@raylink/common/utils/isElementInViewport'
import { Connect } from './component/connect'
import { Scene } from './component/scene'
import { Service } from './component/service'
import { Simple } from './component/simple'

export default function PersonalPage() {
  const [playStatus, setPlayStatus] = useState({
    [WrapId.remoteExplain]: false,
    [WrapId.securityExplain]: false,
  })

  useEffect(() => {
    if (typeof window === 'undefined') return false

    // 有播放效果的组件，当组件出现在浏览器可视化区域大于自身1/2时开始播放效果
    const remoteDom = document.getElementById(WrapId.remoteExplain)
    const securityDom = document.getElementById(WrapId.securityExplain)
    const sceneDom = document.getElementById(WrapId.scene)

    const handle = () => {
      setPlayStatus({
        [WrapId.remoteExplain]: !!(remoteDom && isElementInViewport(remoteDom)),
        [WrapId.securityExplain]: !!(
          securityDom && isElementInViewport(securityDom)
        ),
        [WrapId.scene]: !!(sceneDom && isElementInViewport(sceneDom)),
      })
    }
    window.addEventListener('scroll', handle)

    return () => {
      window.removeEventListener('scroll', handle)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="RayLink远控软件个人版-满足个人及小团体用户远程桌面需求"
        keywords="远程控制,远程桌面,远程连接,远程操控,远程控制桌面,远程桌面控制"
        description="RayLink远控软件搭载高级加密协议，确保远控全程数据安全;远控画质高清，无感延迟，软件性能持续在线!同时，软件界面设计直观且友好，新手也能快速上手，快来体验吧!"
        ogUrl="product/personal/"
      />
      <PersonalWrap>
        <PersonalBanner />
        <ExplainItemsWrap>
          <Explain
            data={remoteExplainData}
            id={WrapId.remoteExplain}
            isPlay={playStatus[WrapId.remoteExplain]}
          />
          <Explain
            data={securityExplainData}
            id={WrapId.securityExplain}
            isPlay={playStatus[WrapId.securityExplain]}
          />
        </ExplainItemsWrap>
        <Connect />
        <Scene id={WrapId.scene} isPlay={playStatus[WrapId.scene]} />
        <Service />
        <Simple />
      </PersonalWrap>
    </Layout>
  )
}
