import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#111"
      d="M22.441 2.376v8.314a.75.75 0 0 1-.744.75l-9.814.078a.75.75 0 0 1-.756-.75v-6.99a.75.75 0 0 1 .644-.742l9.814-1.402a.75.75 0 0 1 .856.742ZM10.094 4.134v6.635a.75.75 0 0 1-.744.75l-7.03.056a.75.75 0 0 1-.756-.75V5.138a.75.75 0 0 1 .643-.742L9.238 3.39a.75.75 0 0 1 .856.743Zm12.347 9.214v8.27a.75.75 0 0 1-.853.742l-9.814-1.363a.75.75 0 0 1-.647-.743V13.31a.75.75 0 0 1 .753-.75l9.814.04a.75.75 0 0 1 .747.75Zm-12.392-.09v6.614a.75.75 0 0 1-.848.744l-6.986-.924a.75.75 0 0 1-.652-.744v-5.727a.75.75 0 0 1 .754-.75l6.986.037a.75.75 0 0 1 .746.75Z"
    />
  </svg>
)
export default SvgComponent
