import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const ExplainWrap = styled(Warpper)``

export const ExplainWebWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isFlip }) => (isFlip ? 'flex-direction: row-reverse;' : '')}
  ${Media.phone`
    display: none;
  `}
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 460px;
  h2 {
    margin: 0;
    font-size: inherit;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
    color: #212121;
  }
  .jump {
    display: block;
    font-size: 14px;
    margin-top: 6px;
    color: #666666;
    font-weight: 500;
    &:hover {
      color: #11c871;
    }
  }
  ${Media.ipad`
    width: 426px;
    .jump {
      margin-top: -16px;
    }
  `}
`

export const FeatListWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background: #eeeeee;
    border-radius: 3px;
  }
`

export const FeatItem = styled.div`
  position: relative;
  display: flex;
  padding-left: 20px;
  transition: all 0.7s;
  cursor: pointer;
  .feat-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .f-title {
    margin: 0;
    font-size: 20px;
    line-height: 1.5;
    color: ${({ isActive }) => (isActive ? '#11C871' : '#333333')};
    font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  }
  .f-desc {
    font-size: 16px;
    line-height: 1.5;
    color: #586978;
  }
  ${({ isActive }) =>
    isActive
      ? `
        &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 100%;
        border-radius: 3px;
        background: #11c871;
      }
      `
      : ''}
  ${Media.phone`
    gap: 0.2rem;
    padding-left: 0;
    .feat-content {
      gap: 0.08rem;
      padding-left: 0.3rem;
    }
    .f-title {
      font-size: 0.3rem;
    }
    .f-desc {
      font-size: 0.24rem;
    }
    ${({ isActive }) =>
      isActive
        ? `
          &::before {
            width: 0.09rem;
            border-radius: 0.05rem;
          }
        `
        : ''}
  `}
`

export const Right = styled.div`
  img {
    width: auto;
    height: 440px;
  }
  ${Media.ipad`
    img {
      height: 330px;
    }
  `}
`

export const ExplainPhoneWrap = styled.div`
  display: none;
  ${Media.phone`
    display: block;
    h2 {
      margin: 0;
    }
    .title {
      margin-bottom: 0.54rem;
      font-size: 0.44rem;
      color: #212121;
      font-weight: 700;
      text-align: center;
    }
    .jump {
      display: block;
      font-size: 0.24rem;
      color: #11C871;
      margin: 0.5rem 0 0 0.44rem;
    }
  `}
`

export const FeatListPhoneWrap = styled.div`
  ${Media.phone`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 6.7rem;
    margin: auto;
  `}
`

export const FeatItemPhone = styled.div`
  ${Media.phone`
    padding: ${({ isActive }) => (isActive ? '0.4rem 0 0.5rem' : '0.17rem 0')};
    border-radius: 0.12rem;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(201, 200, 200, 0.3);
    transition: all 0.7s;
    .feat-img {
      height: 4rem;
      display: block;
      margin: 0.2rem auto 0;
    }
  `}
`
