import React from 'react'
import { compareList, KeyEnum } from '../../config'
import { ServiceWrap, TableTitle, Seemore } from './atom'
import NotFoldTable from '../../../components/noFoldTable'
import { Path } from '@raylink/common/constants/path'

const tableTitle = ({ title, btnText, href, className, blank }) => (
  <TableTitle>
    <div className="t-title">{title}</div>
    <a
      href={href}
      className={`${className || ''} btn`}
      target={blank ? '_blank' : ''}
      rel="noreferrer"
    >
      {btnText || '立即购买'}
    </a>
  </TableTitle>
)

const titleList = [
  {
    label: tableTitle({
      title: '免费体验',
      btnText: '立即下载',
      className: 'line-btn',
      href: Path.download,
    }),
    key: KeyEnum.free,
  },
  {
    label: tableTitle({
      title: '菁英性能会员',
      href: Path.buyEliteVip,
      blank: true,
    }),
    key: KeyEnum.eliteVip,
  },
  {
    label: tableTitle({
      title: '超级性能会员',
      href: Path.buySuperVip,
      blank: true,
    }),
    key: KeyEnum.superVip,
  },
  {
    label: tableTitle({
      title: '全球性能会员',
      href: Path.buyGlobalVip,
      blank: true,
    }),
    key: KeyEnum.globalVip,
  },
]

export function Service() {
  return (
    <ServiceWrap>
      {/** 开学季活动 */}
      <div className="tag">
        <span>开学季限时7折起</span>
      </div>
      <h2 className="title">享受会员权益与服务</h2>
      <NotFoldTable compareList={compareList} titleList={titleList} />
      <Seemore>
        <a href={Path.price}>查看更多会员特权 &gt;&gt;</a>
      </Seemore>
    </ServiceWrap>
  )
}
