import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#111"
        d="M12 24C5.374 24 0 18.625 0 12 0 5.374 5.375 0 12 0c6.626 0 12 5.375 12 12 0 6.626-5.375 12-12 12Zm4.376-11.084-2.918-5.041c-.584.5-1.166 1.916-.333 3.334l3.667 6.333c.25.458.875.624 1.332.375.46-.25.626-.876.375-1.334l-.957-1.666h1.416c.542 0 1-.417 1-.959 0-.541-.417-1-.958-1h-2.625v-.041Zm-8.168 2.958c-.5-.624-1.167-.79-1.958-.582L5.542 16.5c-.25.458-.084 1.083.375 1.334.457.25 1.04.083 1.332-.334 0 .042.959-1.626.959-1.626ZM12 5.334l-.458-.75c-.292-.458-.876-.625-1.335-.333-.458.292-.624.875-.375 1.333l1 1.75-3.207 5.583h-2.5c-.542 0-1 .417-1 .959 0 .54.416 1 .959 1h9c.416-.792-.126-1.959-1.125-1.959H9.875l4.249-7.335a.953.953 0 0 0-.333-1.332.954.954 0 0 0-1.333.333l-.458.75v.001Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
