import Remote1 from '../../../images/product/personal/explain/remote1.png'
import Remote2 from '../../../images/product/personal/explain/remote2.png'
import Remote3 from '../../../images/product/personal/explain/remote3.png'
import Remote4 from '../../../images/product/personal/explain/remote4.png'
import Security1 from '../../../images/product/personal/explain/security1.png'
import Security2 from '../../../images/product/personal/explain/security2.png'
import Security3 from '../../../images/product/personal/explain/security3.png'
import Connect1 from '../../../images/product/personal/connect/connect1.png'
import Connect2 from '../../../images/product/personal/connect/connect2.png'
import Connect3 from '../../../images/product/personal/connect/connect3.png'
import Scene1 from '../../../images/product/personal/scene/img1.png'
import Scene2 from '../../../images/product/personal/scene/img2.png'
import Scene3 from '../../../images/product/personal/scene/img3.png'
import Scene4 from '../../../images/product/personal/scene/img4.png'

// 组件模块的id
export const WrapId = {
  remoteExplain: 'remote-explain',
  securityExplain: 'security-explain',
  scene: 'scene',
}

export const remoteExplainData = {
  title: '追求一流远程体验',
  featList: [
    {
      name: '超低延时',
      imgSrc: Remote1,
      desc: '低至10ms实时快速响应，体验宛如本地的流畅操作感',
    },
    {
      name: '高清画质',
      imgSrc: Remote2,
      desc: '提供超高清视觉体验，细致展现远程操控的每一个细节',
    },
    {
      name: '跨端互连',
      imgSrc: Remote3,
      desc: '电脑、手机、平板，均能轻松实现跨平台远程控制，工作娱乐两不误',
    },
    {
      name: '全球线路',
      imgSrc: Remote4,
      desc: '覆盖全球多地区优质线路，稳定畅通实现远程连接',
    },
  ],
}

export const securityExplainData = {
  title: '用心守护数据安全',
  jumpText: '安全远控解决方案 >>',
  isFlip: true,
  featList: [
    {
      name: '加密技术',
      imgSrc: Security1,
      desc: '采用AES-256金融级加密协议，确保数据传输安全性，严防信息窃取与泄露',
    },
    {
      name: '隐私保护',
      imgSrc: Security2,
      desc: '部署防窥模式、防骚扰模式、事后自动锁屏等多种隐私安全措施，提供贴心守护',
    },
    {
      name: '权限管理',
      imgSrc: Security3,
      desc: '切实保护受控端利益，赋予其限制主控端操作行为的权力，对恶意远控说“不”',
    },
  ],
}

export const connectList = [
  {
    index: '01',
    title: '安装客户端',
    text: '在主、被控设备下载并安装RayLink客户端',
    desc: '* 若主控使用网页远程，则无需下载客户端；若受控使用绿色版客户端，可免安装运行',
    imgSrc: Connect1,
  },
  {
    index: '02',
    title: '登录账户',
    text: '启动客户端，注册并登录账户',
    imgSrc: Connect2,
  },
  {
    index: '03',
    title: '建立远程连接',
    text: '在主控设备中输入受控设备ID，发起远程连接',
    imgSrc: Connect3,
  },
]

export const sceneList = [
  {
    name: '远程办公',
    title: '超多提效工具，助力远程办公',
    imgSrc: Scene1,
    featList: [
      '跨端文件传输，不限数量、格式、大小',
      '利用防窥模式将受控黑屏且静音，以防被窥探',
      '支持多屏对多屏远程，高效处理工作',
    ],
    jump: {
      text: '远程办公解决方案 >>',
      url: '/',
    },
  },
  {
    name: '远程游戏',
    title: '游戏专属功能，随时玩，随地玩',
    imgSrc: Scene2,
    featList: [
      '144FPS电竞级帧率，刷3A大作无压力',
      '自由转动3D视角，如本地般身临其境',
      '手机定制游戏键盘，端游一秒变手游',
    ],
    jump: {
      text: '远程游戏解决方案 >>',
      url: '/',
    },
  },
  {
    name: '手机远控',
    title: '移动远控更便捷，更精准，更安全',
    imgSrc: Scene3,
    featList: [
      '支持安卓、iOS设备作为主控或受控',
      '鼠标指针模式，点击比触控更精准',
      '连接会话稳定，移动办公更顺畅',
    ],
  },
  {
    name: '远程设计',
    title: '极致图像还原，创意触手可及',
    imgSrc: Scene4,
    featList: [
      '支持主流设计软件',
      '4:4:4色彩模式还原真实图像',
      '支持数位板映射，灵敏还原压感',
    ],
  },
]

export const KeyEnum = {
  free: 'free',
  eliteVip: 'eliteVip',
  superVip: 'superVip',
  globalVip: 'globalVip',
}

export const compareList = [
  {
    name: '远程线路',
    rows: [
      { key: KeyEnum.free, value: '中国大陆' },
      { key: KeyEnum.eliteVip, value: '中国大陆' },
      { key: KeyEnum.superVip, value: '中国大陆' },
      {
        key: KeyEnum.globalVip,
        value: '全球地区',
        tip: '满足200余个国家及地区的跨境远程需求',
        href: 'https://www.raylink.live/helpcenter/support-1055.html',
        hrefText: '查看支持的国家与地区>>',
      },
    ],
  },
  {
    name: '画质',
    rows: [
      { key: KeyEnum.free, value: '流畅' },
      { key: KeyEnum.eliteVip, value: '高清' },
      { key: KeyEnum.superVip, value: '超高清' },
      { key: KeyEnum.globalVip, value: '超高清' },
    ],
  },
  {
    name: '帧率',
    rows: [
      { key: KeyEnum.free, value: '最高30FPS' },
      { key: KeyEnum.eliteVip, value: '最高60FPS' },
      { key: KeyEnum.superVip, value: '最高144FPS' },
      { key: KeyEnum.globalVip, value: '最高144FPS' },
    ],
  },
  {
    name: '色彩模式',
    rows: [
      { key: KeyEnum.free, value: '4 : 2 : 0' },
      { key: KeyEnum.eliteVip, value: '4 : 4 : 4' },
      { key: KeyEnum.superVip, value: '4 : 4 : 4' },
      { key: KeyEnum.globalVip, value: '4 : 4 : 4' },
    ],
  },
  {
    name: '文件传输',
    rows: [
      { key: KeyEnum.free, value: '最高速度5M' },
      { key: KeyEnum.eliteVip, value: '不限速' },
      { key: KeyEnum.superVip, value: '不限速' },
      { key: KeyEnum.globalVip, value: '不限速' },
    ],
  },
  {
    name: '多屏控多屏',
    rows: [
      { key: KeyEnum.free, value: true },
      { key: KeyEnum.eliteVip, value: true },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
  {
    name: '防窥模式',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.eliteVip, value: true },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
  {
    name: '录屏',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.eliteVip, value: true },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
  {
    name: '远控移动设备',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.eliteVip, value: false },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
  {
    name: '游戏专属功能',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.eliteVip, value: false },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
  {
    name: '数位板',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.eliteVip, value: false },
      { key: KeyEnum.superVip, value: true },
      { key: KeyEnum.globalVip, value: true },
    ],
  },
]
