import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#111"
      d="M15.263 2.11 16.412.29a.182.182 0 0 0-.04-.238L16.343.03a.15.15 0 0 0-.218.044l-1.181 1.871a5.917 5.917 0 0 0-2.541-.57c-.909 0-1.682.185-2.422.516L8.85.103a.15.15 0 0 0-.218-.044l-.028.022a.183.183 0 0 0-.04.238l1.091 1.729a6.06 6.06 0 0 0-3.2 4.622H18.35a6.06 6.06 0 0 0-3.087-4.56ZM9.898 4.714c-.327 0-.593-.29-.593-.648 0-.358.266-.647.593-.647.327 0 .593.29.593.647 0 .358-.266.648-.593.648Zm5.117 0c-.327 0-.593-.29-.593-.648 0-.358.266-.647.593-.647.327 0 .592.29.592.647 0 .358-.265.648-.592.648ZM5.628 9.265v5.306c0 .91-.532 1.654-1.183 1.654h-.262c-.651 0-1.183-.744-1.183-1.654V9.265c0-.91.532-1.653 1.183-1.653h.262c.65 0 1.183.744 1.183 1.653ZM21.887 9.297v5.305c0 .91-.532 1.654-1.182 1.654h-.263c-.65 0-1.183-.744-1.183-1.654V9.297c0-.91.533-1.654 1.183-1.654h.263c.65 0 1.183.744 1.183 1.654ZM18.435 7.53V18c0 .726-.545 1.32-1.21 1.32h-.997v3.026c0 .91-.533 1.654-1.183 1.654h-.262c-.65 0-1.183-.744-1.183-1.654v-3.025h-2.222v3.025c0 .91-.532 1.654-1.183 1.654h-.263c-.65 0-1.182-.744-1.182-1.654v-3.025H7.662c-.665 0-1.209-.595-1.209-1.321V7.53h11.982Z"
    />
  </svg>
)
export default SvgComponent
