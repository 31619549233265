import React from 'react'
import Windows from '../../../../../images/product/personal/banner/windows'
import Mac from '../../../../../images/product/personal/banner/mac'
import Android from '../../../../../images/product/personal/banner/android'
import Ios from '../../../../../images/product/personal/banner/ios'
import Linux from '../../../../../images/product/personal/banner/linux'
import Web from '../../../../../images/product/personal/banner/web'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { Path } from '@raylink/common/constants/path'
import { PersonalBannerWrap, PersonalBannerContent, BtnListWrap } from './atom'

const btnList = [<Windows />, <Mac />, <Android />, <Ios />, <Linux />, <Web />]

export function PersonalBanner({ ...rest }) {
  return (
    <PersonalBannerWrap {...rest}>
      <PersonalBannerContent>
        <div className="tag">RayLink远程控制·个人版</div>
        <div className="title">
          <h1>安全·秒会·好用的</h1>
          <h1>远程控制软件</h1>
        </div>
        <BannerBtns
          btnInfo={{
            first: {
              text: '立即下载',
              href: Path.download,
            },
            second: {
              text: '购买会员',
              href: Path.price,
            },
          }}
          className="p-btns"
        />
        <BtnListWrap>
          <div>产品适用于：</div>
          {btnList.map((item, index) => (
            <a href={Path.download} key={index}>
              {item}
            </a>
          ))}
        </BtnListWrap>
      </PersonalBannerContent>
    </PersonalBannerWrap>
  )
}
