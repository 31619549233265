import { Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import { Media } from '@raylink/common/theme'

export const ServiceWrap = styled.div`
  padding: 80px 0 90px;
  /** 开学季活动 */
  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: fit-content;
    height: 30px;
    padding: 0 32px;
    border-radius: 16px;
    background: linear-gradient(
      90deg,
      rgba(17, 200, 113, 0.08) 0%,
      rgba(66, 175, 215, 0.08) 100%
    );
    span {
      font-family: 'Source Han Sans CN';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: linear-gradient(90deg, #11c871 0%, #42afd7 100%);
      color: transparent;
      -webkit-background-clip: text;
    }
  }
  .title {
    margin: 10px auto 26px;
    font-size: 36px;
    color: #1f2329;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
  }
  ${Media.ipad`
    padding: 60px 0 70px;
    .see-more-feature {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 37px;
      font-size: 14px;
      background: #f4f7f9;
      a {
        color: #11c871;
        &:hover {
          font-weight: 500;
        }
      }
    }
  `}
  ${Media.phone`
    padding: 0.8rem 0.4rem 0.7rem;
    .tag {
      height: 0.45rem;
      padding: 0 0.8rem;
      border-radius: 0.23rem;
    }
    .title {
      margin: 0.15rem auto 0.34rem;
      font-size: 0.44rem;
    }
    .see-more-feature {
      height: 0.6rem;
      font-size: 0.21rem;
    }
  `}
`

export const Seemore = styled(Warpper)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  font-size: 14px;
  background: #f4f7f9;
  a {
    color: #666;
    font-weight: 500;
    &:hover {
      color: #11c871;
    }
  }
  ${Media.ipad`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    font-size: 14px;
    background: #f4f7f9;
    a {
      color: #11c871;
      &:hover {
        font-weight: 500;
      }
    }
  `}
  ${Media.phone`
    height: 0.6rem;
    font-size: 0.21rem;
  `}
`

export const TableTitle = styled.div`
  text-align: center;
  .t-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: auto;
    padding: 0 30px;
    height: 33px;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 500;
    color: #fff;
    background: #11c871;
    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(17, 200, 113, 0.3);
    }
  }
  .line-btn {
    box-sizing: border-box;
    color: #11c871;
    background: transparent;
    border: 1px solid #11c871;
    &:hover {
      color: #fff;
      background: #11c871;
      box-shadow: none;
    }
  }
  ${Media.phone`
    padding: 0;
    .t-title {
      font-size: 0.2rem;
    }
    .btn {
      padding: 0 0.2rem;
      height: 0.48rem;
      font-size: 0.18rem;
      border-radius: 0.09rem;
    }
  `}
`
