import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const SceneWrap = styled.div`
  text-align: center;
  .title {
    margin: 60px 0 6px;
    font-size: 36px;
    color: #212121;
    font-weight: 700;
  }
  .see-more {
    font-size: 16px;
    margin: 0 auto;
    display: block;
    width: fit-content;
    color: #666;
    font-weight: 500;
    &:hover {
      color: #11c871;
    }
  }
  ${Media.ipad`
    .title {
      margin: 40px 0 6px;
    }
    .see-more {
      font-size: 16px;
    }
  `}
  ${Media.phone`
    .title {
      margin: 0.8rem 0 0.24rem;
      font-size: 0.44rem;
    }
    .see-more {
      font-size: 0.24rem;
      margin: 0 auto;
    }
  `}
`

export const FeatHeadListWrap = styled.div`
  margin: 40px auto 0;
  display: flex;
  gap: 20px;
  width: 700px;
  ${Media.phone`
    margin: 0.54rem auto 0;
    gap: 0.4rem;
    width: 6rem;
  `}
`

export const FeatHeadItem = styled.div`
  width: 160px;
  font-size: 18px;
  margin: auto;
  text-align: center;
  transition: all 0.7s;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#11C871' : '#444')};
  > div {
    font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  }
  img {
    transition: all 0.7s;
    width: 100%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }
  .phone-item {
    display: none;
  }
  ${Media.phone`
    width: 1.12rem;
    font-size: 0.28rem;
    > div {
      white-space: nowrap;
    }
    .web-item {
      display: none;
    }
    .phone-item {
      display: block;
    }
  `}
`

export const FeatListWrap = styled.div`
  padding: 70px 0;
  background: rgba(227, 255, 242, 0.4);
  ${Media.ipad`
    padding: 60px 0;
  `}
  ${Media.phone`
    padding: 0.6rem 0 0.84rem;
  `}
`

export const FeatItemContent = styled(Warpper)`
  display: flex;
  justify-content: space-between;
  .right-img {
    width: 640px;
  }
  ${Media.ipad`
    .right-img {
      width: 480px;
    }
  `}
  ${Media.phone`
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.43rem;
    .right-img {
      width: 6.4rem;
    }
  `}
`

export const FeatItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  h3 {
    margin: 0;
  }
  .f-title {
    font-size: 24px;
    font-weight: 700;
    color: #212121;
    text-align: left;
  }
  .jump-link {
    font-size: 14px;
    text-align: left;
    display: block;
    color: #11c871;
    &:hover {
      font-weight: 500;
    }
  }
  ${Media.phone`
    gap: 0;
    .f-title {
      font-size: 0.36rem;
    }
    .jump-link {
      font-size: 0.24rem;
    }
  `}
`

export const DescList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .f-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #002b36;
    line-height: 1.5;
  }
  ${Media.phone`
    margin: 0.2rem 0 0.37rem;
    gap: 0.2rem;
    .f-item {
      gap: 0.15rem;
      font-size: 0.28rem;
    }
  `}
`
