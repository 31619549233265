import React from 'react'
import LogoImg from '../../../../../images/white-logo.svg'
import { SimpleWrap, SimpleContent } from './atom'
import BannerBtns from '../../../../../components/home/bannerBtns'

export function Simple() {
  return (
    <SimpleWrap>
      <SimpleContent>
        <img src={LogoImg} alt="" className="white-logo" loading="lazy" />
        <h2 className="title">安全、简单、高性能远程控制</h2>
        <BannerBtns
          btnInfo={{
            first: {
              text: '立即下载',
              href: '/download.html',
            },
            second: {
              text: '购买会员',
              href: '/price.html',
            },
          }}
          className="simple-btns"
        />
      </SimpleContent>
    </SimpleWrap>
  )
}
