import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'
import styled from 'styled-components'
import { SchoolOpenActivity } from '../../index.style'

export const ConnectWrap = styled.div`
  padding: 80px 0 76px;
  background: linear-gradient(180deg, rgba(227, 255, 242, 0.4) 0%, #fff 100%);
  ${Media.ipad`
    padding: 60px 0 56px;
  `}
  ${Media.phone`
    padding: 0.8rem 0.54rem;
  `}
`

export const ConnectContent = styled(Warpper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    margin: 0;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    color: #212121;
  }
  /** 开学季活动 */
  .p-btns {
    ${SchoolOpenActivity}
  }
  ${Media.phone`
    .title {
      font-size: 0.44rem;
    }
  `}
`

export const StepListWrap = styled.div`
  display: flex;
  gap: 10px;
  margin: 66px auto 40px;
  .arrow-img {
    width: 70px;
    height: fit-content;
    margin: auto;
  }
  ${Media.ipad`
    gap: 3px;
    .arrow-img {
      width: 36px;
    }
  `}
  ${Media.phone`
    flex-direction: column;
    gap: 0.16rem;
    margin: 0.54rem auto 0.66rem;
    .arrow-img {
      width: 0.8rem;
      height: 1.44rem;
      margin: -0.42rem auto;
      transform: rotate(90deg);
    }
  `}
`

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(204, 204, 204, 0.2);
  background: rgba(255, 255, 255, 0.6);
  text-align: left;
  line-height: 1.5;
  .i-title {
    font-size: 20px;
    font-weight: 700;
    color: #212121;
    span {
      margin-right: 10px;
      color: #11c871;
    }
  }
  .i-text {
    font-size: 16px;
    color: #586978;
  }
  .i-desc {
    font-size: 14px;
    color: #878787;
  }
  .i-img {
    width: 300px;
  }
  ${Media.ipad`
    .i-img {
      width: 240px;
    }
  `}
  ${Media.phone`
    gap: 0.2rem;
    padding: 0.4rem;
    border-radius: 0.24rem;
    .i-title {
      font-size: 0.36rem;
      span {
        margin-right: 0.18rem;
      }
    }
    .i-text {
      font-size: 0.28rem;
    }
    .i-desc {
      font-size: 0.24rem;
    }
    .i-img {
      width: 5.6rem;
    }
  `}
`
