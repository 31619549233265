import React, { useEffect, useState } from 'react'
import { sceneList } from '../../config'
import BottomImg from '../../../../../images/product/personal/scene/sceneBottom.svg'
import BottomPhoneImg from '../../../../../images/product/personal/scene/sceneBottomPhone.svg'
import CheckImg from '../../../../../images/product/personal/scene/check.svg'
import {
  SceneWrap,
  FeatHeadListWrap,
  FeatHeadItem,
  FeatListWrap,
  FeatItemContent,
  FeatItemLeft,
  DescList,
} from './atom'

export function Scene({ isPlay, id }) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const interval = setInterval(() => {
      if (activeIndex === sceneList.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(pre => pre + 1)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [activeIndex, isPlay])

  return (
    <SceneWrap id={id}>
      <h2 className="title">远程桌面场景，高效轻松应对</h2>
      {/* <a href="/" className="see-more">
        了解产品详细功能 &gt;&gt;
      </a> */}
      <FeatHeadListWrap>
        {sceneList.map((item, index) => (
          <FeatHeadItem
            key={item.name}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          >
            <div>{item.name}</div>
            <img src={BottomImg} alt="" className="web-item" loading="lazy" />
            <img
              src={BottomPhoneImg}
              alt=""
              className="phone-item"
              loading="lazy"
            />
          </FeatHeadItem>
        ))}
      </FeatHeadListWrap>
      <FeatListWrap>
        <FeatItemContent>
          <FeatItemLeft>
            <h3 className="f-title">{sceneList[activeIndex]?.title}</h3>
            <DescList>
              {sceneList[activeIndex]?.featList.map(d => (
                <div key={d} className="f-item">
                  <img src={CheckImg} alt="" loading="lazy" />
                  {d}
                </div>
              ))}
            </DescList>
            {/* {sceneList[activeIndex]?.jump?.text && (
              <a href={sceneList[activeIndex]?.jump.url} className="jump-link">
                {sceneList[activeIndex]?.jump.text}
              </a>
            )} */}
          </FeatItemLeft>
          <img
            src={sceneList[activeIndex]?.imgSrc}
            alt=""
            className="right-img"
            loading="lazy"
          />
        </FeatItemContent>
      </FeatListWrap>
    </SceneWrap>
  )
}
