// 当元素处于可视化区域大于 1/2 时判断为true
export function isElementInViewport(el) {
  const totalHeight =
    window.innerHeight || document.documentElement.clientHeight
  const elHeight = el?.clientHeight || 0
  const totalWidth = window.innerWidth || document.documentElement.clientWidth
  const { top, right, left } = el.getBoundingClientRect()

  return (
    top >= 0 &&
    left >= 0 &&
    right <= totalWidth &&
    totalHeight - top > elHeight / 2
  )
}
